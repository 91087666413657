<template>
  <div>
    <vs-popup fullscreen buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD ? SphVD : ''}}, Cylindre: {{CylVD ? CylVD : ''}}, Axe: {{AxeVD ? `${AxeVD}°` : ''}}, Addition: {{AddVD ? AddVD : ''}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD ? SphVD : ''}}, Cylindre: {{CylVD ? CylVD : ''}}, Axe: {{AxeVD ? `${AxeVD}°` : ''}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD1) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD1) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD1) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD1) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD1 + +Prix_TraitementOD1 + +Prix_FabricationOD1 + +Prix_ColorationOD1))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD1 + +Prix_TraitementOD1 + +Prix_FabricationOD1 + +Prix_ColorationOD1))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG1) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG1) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG1) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG1) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG1 + +Prix_TraitementOG1 + +Prix_FabricationOG1 + +Prix_ColorationOG1))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG1 + +Prix_TraitementOG1 + +Prix_FabricationOG1 + +Prix_ColorationOG1))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre=true;showOGCommandeVerre=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD1 === IdFourVerrOG1) && (Id_FoyerOD1 === Id_FoyerOG1) && (IdGammeVerrOD1 === IdGammeVerrOG1) && (Id_MatiereOD1 === Id_MatiereOG1) && (Id_IndiceOD1 === Id_IndiceOG1) && (Id_OptionVerreOD1 === Id_OptionVerreOG1) && (Id_TraitementOD1 === Id_TraitementOG1) && (Id_FabricationOD1 === Id_FabricationOG1) && (Id_ColorationOD1 === Id_ColorationOG1) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG1=IdFourVerrOD1;Id_FoyerOG1=Id_FoyerOD1;IdGammeVerrOG1=IdGammeVerrOD1;Id_MatiereOG1=Id_MatiereOD1;Id_IndiceOG1=Id_IndiceOD1;Id_OptionVerreOG1=Id_OptionVerreOD1;Id_TraitementOG1=Id_TraitementOD1;Id_FabricationOG1=Id_FabricationOD1;Id_ColorationOG1=Id_ColorationOD1;RefVerG1=RefVerD1;PrixVerG1=PrixVerD1;Prix_OptionVerreOG1=Prix_OptionVerreOD1;Prix_TraitementOG1=Prix_TraitementOD1;Prix_FabricationOG1=Prix_FabricationOD1;Prix_ColorationOG1=Prix_ColorationOD1" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre=false;showOGCommandeVerre=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD1">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD1" @change="setOptionODPrice1(Id_OptionVerreOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD1" @change="setTraitementODPrice1(Id_TraitementOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD1" @change="setFabricationODPrice1(Id_FabricationOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD1" @change="setColorationODPrice1(Id_ColorationOD1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD1 || Id_FoyerOD1 || IdGammeVerrOD1 || Id_MatiereOD1 || Id_IndiceOD1 || Id_OptionVerreOD1 || Id_TraitementOD1 || Id_FabricationOD1 || Id_ColorationOD1)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD1=undefined;Id_FoyerOD1=undefined;IdGammeVerrOD1=undefined;Id_MatiereOD1=undefined;Id_IndiceOD1=undefined;Id_OptionVerreOD1=undefined;Id_TraitementOD1=undefined;Id_FabricationOD1=undefined;Id_ColorationOD1=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG1" @change="filterVerreDataOD1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG1">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG1" @change="filterVerreDataOG1()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG1" @change="setOptionOGPrice1(Id_OptionVerreOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG1" @change="setTraitementOGPrice1(Id_TraitementOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG1" @change="setFabricationOGPrice1(Id_FabricationOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG1" @change="setColorationOGPrice1(Id_ColorationOG1)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG1 || Id_FoyerOG1 || IdGammeVerrOG1 || Id_MatiereOG1 || Id_IndiceOG1 || Id_OptionVerreOG1 || Id_TraitementOG1 || Id_FabricationOG1 || Id_ColorationOG1)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG1=undefined;Id_FoyerOG1=undefined;IdGammeVerrOG1=undefined;Id_MatiereOG1=undefined;Id_IndiceOG1=undefined;Id_OptionVerreOG1=undefined;Id_TraitementOG1=undefined;Id_FabricationOG1=undefined;Id_ColorationOG1=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD1();prixVerreOG1();popupCatalogue=false">Valider</vs-button>
            </div>
          </div>

        </div>
      </div>

    </vs-popup>
    <vs-popup fullscreen buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue2">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°, Addition: {{AddVD}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD2) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD2) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD2) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD2) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD2 + +Prix_TraitementOD2 + +Prix_FabricationOD2 + +Prix_ColorationOD2))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD2 + +Prix_TraitementOD2 + +Prix_FabricationOD2 + +Prix_ColorationOD2))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG2) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG2) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG2) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG2) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG2 + +Prix_TraitementOG2 + +Prix_FabricationOG2 + +Prix_ColorationOG2))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG2 + +Prix_TraitementOG2+ +Prix_FabricationOG2+ +Prix_ColorationOG2))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre2=true;showOGCommandeVerre2=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD2 === IdFourVerrOG2) && (Id_FoyerOD2 === Id_FoyerOG2) && (IdGammeVerrOD2 === IdGammeVerrOG2) && (Id_MatiereOD2 === Id_MatiereOG2) && (Id_IndiceOD2 === Id_IndiceOG2) && (Id_OptionVerreOD2 === Id_OptionVerreOG2) && (Id_TraitementOD2 === Id_TraitementOG2) && (Id_FabricationOD2 === Id_FabricationOG2) && (Id_ColorationOD2 === Id_ColorationOG2) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG2=IdFourVerrOD2;Id_FoyerOG2=Id_FoyerOD2;IdGammeVerrOG2=IdGammeVerrOD2;Id_MatiereOG2=Id_MatiereOD2;Id_IndiceOG2=Id_IndiceOD2;Id_OptionVerreOG2=Id_OptionVerreOD2;Id_TraitementOG2=Id_TraitementOD2;Id_FabricationOG2=Id_FabricationOD2;Id_ColorationOG2=Id_ColorationOD2;RefVerG2=RefVerD2;PrixVerG2=PrixVerD2;Prix_OptionVerreOG2=Prix_OptionVerreOD2;Prix_TraitementOG2=Prix_TraitementOD2;Prix_FabricationOG2=Prix_FabricationOD2;Prix_ColorationOG2=Prix_ColorationOD2" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre2=false;showOGCommandeVerre2=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD2">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD2" @change="setOptionODPrice2(Id_OptionVerreOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD2" @change="setTraitementODPrice2(Id_TraitementOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD2" @change="setFabricationODPrice2(Id_FabricationOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD2" @change="setColorationODPrice2(Id_ColorationOD2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD2 || Id_FoyerOD2 || IdGammeVerrOD2 || Id_MatiereOD2 || Id_IndiceOD2 || Id_OptionVerreOD2 || Id_TraitementOD2 || Id_FabricationOD2 || Id_ColorationOD2)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD2=undefined;Id_FoyerOD2=undefined;IdGammeVerrOD2=undefined;Id_MatiereOD2=undefined;Id_IndiceOD2=undefined;Id_OptionVerreOD2=undefined;Id_TraitementOD2=undefined;Id_FabricationOD2=undefined;Id_ColorationOD2=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre2">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG2" @change="filterVerreDataOD2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG2">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG2" @change="filterVerreDataOG2()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG2" @change="setOptionOGPrice2(Id_OptionVerreOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG2" @change="setTraitementOGPrice2(Id_TraitementOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG2" @change="setFabricationOGPrice2(Id_FabricationOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG2" @change="setColorationOGPrice2(Id_ColorationOG2)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG2 || Id_FoyerOG2 || IdGammeVerrOG2 || Id_MatiereOG2 || Id_IndiceOG2 || Id_OptionVerreOG2 || Id_TraitementOG2 || Id_FabricationOG2 || Id_ColorationOG2)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG2=undefined;Id_FoyerOG2=undefined;IdGammeVerrOG2=undefined;Id_MatiereOG2=undefined;Id_IndiceOG2=undefined;Id_OptionVerreOG2=undefined;Id_TraitementOG2=undefined;Id_FabricationOG2=undefined;Id_ColorationOG2=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue2=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD2();prixVerreOG2();popupCatalogue2=false">Valider</vs-button>
            </div>
          </div>
        </div>
      </div>

    </vs-popup>
    <vs-popup fullscreen buttonCloseHidden="true" title="Catalogue de verre" :active.sync="popupCatalogue3">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card title="Valeurs Corrections" :noShadow="true" :cardBorder="true">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <h4>Oeil Droit</h4>
                <template v-if="parseFloat(CylVD) > 0" >
                  <h5>Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°, Addition: {{AddVD}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVD))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVD))}}, Axe: {{ (parseInt(AxeVD) + 90) > 180 ? parseInt(AxeVD) + 90 - 180 : parseInt(AxeVD) + 90 }},
                    Addition: {{AddVD}}
                  </h5>
                  <h5>(Sphère: {{SphVD}}, Cylindre: {{CylVD}}, Axe: {{AxeVD}}°)</h5>
                </template>
              </div>
              <div class="vx-col md:w-1/2">
                <h4>Oeil Gauche</h4>
                <template v-if="parseFloat(CylVG) > 0" >
                  <h5>Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°, Addition: {{AddVG}}</h5>
                  <h5>
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVD) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }}
                  </h5>
                </template>
                <template v-else>
                  <h5 class="w-full">
                    Sphère : {{Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 1 || Math.sign(parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)) === 0 ? `+${parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}` : parseFloat((parseFloat(SphVG) + parseFloat(CylVG))).toFixed(2)}}
                    , Cylindre: {{-(parseFloat(CylVG))}}, Axe: {{ (parseInt(AxeVG) + 90) > 180 ? parseInt(AxeVG) + 90 - 180 : parseInt(AxeVG) + 90 }},
                    Addition: {{AddVG}}
                  </h5>
                  <h5>(Sphère: {{SphVG}}, Cylindre: {{CylVG}}, Axe: {{AxeVG}}°)</h5>
                </template>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Porteur" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <span>Nom: {{client ? client.Nom : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Téléphone: {{client ? client.Adresse.mobile : ''}} /{{client ?client.Adresse.tel : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Prénom: {{client ? client.Prenoms : ''}}</span>
              </div>
              <div class="vx-col md:w-1/2">
                <span>Email: {{client ? client.Adresse.email : ''}}</span>
              </div>
            </div>
          </vx-card>
          <vx-card class="mt-4" title="Prix Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col w-full">
                <span>OEIL Droit:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOD3) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOD3) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOD3) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOD3) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOD3 + +Prix_TraitementOD3 + +Prix_FabricationOD3 + +Prix_ColorationOD3))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOD3 + +Prix_TraitementOD3 + +Prix_FabricationOD3 + +Prix_ColorationOD3))}}</span>
                <br><br>
                <span>OEIL Gauche:</span> <br>
                <span>Option: {{ moneyFormatter(Prix_OptionVerreOG3) }}</span> <br>
                <span>Traitement: {{ moneyFormatter(Prix_TraitementOG3) }}</span> <br>
                <span>Fabrication/Supplement: {{ moneyFormatter(Prix_FabricationOG3) }}</span> <br>
                <span>Coloration: {{ moneyFormatter(Prix_ColorationOG3) }}</span> <br>
                <span>Montant HT: {{moneyFormatter((+Prix_OptionVerreOG3 + +Prix_TraitementOG3 + +Prix_FabricationOG3 + +Prix_ColorationOG3))}}</span> <br>
                <span>Montant TTC: {{moneyFormatter((+Prix_OptionVerreOG3 + +Prix_TraitementOG3 + +Prix_FabricationOG3+ +Prix_ColorationOG3))}}</span>
              </div>
            </div>
          </vx-card>
        </div>
        <div class="vx-col md:w-1/2">
          <vx-card title="Verres" :noShadow="true" :cardBorder="true" >
            <div class="vx-row">
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnODColor" class="w-full" type="filled" @click="btnODColor='primary';btnOGColor='lightgray';showODCommandeVerre3=true;showOGCommandeVerre3=false">OD</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button v-if="((IdFourVerrOD3 === IdFourVerrOG3) && (Id_FoyerOD3 === Id_FoyerOG3) && (IdGammeVerrOD3 === IdGammeVerrOG3) && (Id_MatiereOD3 === Id_MatiereOG3) && (Id_IndiceOD3 === Id_IndiceOG3) && (Id_OptionVerreOD3 === Id_OptionVerreOG3) && (Id_TraitementOD3 === Id_TraitementOG3) && (Id_FabricationOD3 === Id_FabricationOG3) && (Id_ColorationOD3 === Id_ColorationOG3) )"  color="primary" class="" type="filled">=</vs-button>
                <vs-button v-else  color="danger" class="" type="filled" @click="IdFourVerrOG3=IdFourVerrOD3;Id_FoyerOG3=Id_FoyerOD3;IdGammeVerrOG3=IdGammeVerrOD3;Id_MatiereOG3=Id_MatiereOD3;Id_IndiceOG3=Id_IndiceOD3;Id_OptionVerreOG3=Id_OptionVerreOD3;Id_TraitementOG3=Id_TraitementOD3;Id_FabricationOG3=Id_FabricationOD3;Id_ColorationOG3=Id_ColorationOD3;RefVerG3=RefVerD3;PrixVerG3=PrixVerD3;Prix_OptionVerreOG3=Prix_OptionVerreOD3;Prix_TraitementOG3=Prix_TraitementOD3;Prix_FabricationOG3=Prix_FabricationOD3;Prix_ColorationOG3=Prix_ColorationOD3" >≠</vs-button>
              </div>
              <div class="vx-col md:w-1/3 text-center">
                <vs-button :color="btnOGColor" class="w-full" type="filled" @click="btnODColor='lightgray';btnOGColor='primary';showODCommandeVerre3=false;showOGCommandeVerre3=true">OG</vs-button>
              </div>
            </div>
            <template v-if="showODCommandeVerre3">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOD3">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOD3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOD3" @change="setOptionODPrice3(Id_OptionVerreOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOD3" @change="setTraitementODPrice3(Id_TraitementOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOD3" @change="setFabricationODPrice3(Id_FabricationOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOD3" @change="setColorationODPrice3(Id_ColorationOD3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOD3 || Id_FoyerOD3 || IdGammeVerrOD3 || Id_MatiereOD3 || Id_IndiceOD3 || Id_OptionVerreOD3 || Id_TraitementOD3 || Id_FabricationOD3 || Id_ColorationOD3)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOD3=undefined;Id_FoyerOD3=undefined;IdGammeVerrOD3=undefined;Id_MatiereOD3=undefined;Id_IndiceOD3=undefined;Id_OptionVerreOD3=undefined;Id_TraitementOD3=undefined;Id_FabricationOD3=undefined;Id_ColorationOD3=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
            <template v-if="showOGCommandeVerre3">
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fournisseur" class="w-full mt-4"  autocomplete v-model="IdFourVerrOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Fournisseur}`" v-for="(item,index) in fournisseur_verres_data" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Type de verre (Foyer)" class="w-full mt-4"  autocomplete v-model="Id_FoyerOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Foyer}`" v-for="(item,index) in typeVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Gamme de verre" class="w-full mt-4"  autocomplete v-model="IdGammeVerrOG3" @change="filterVerreDataOD3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_GamVer}`" v-for="(item,index) in gammeVerresData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Matière" class="w-full mt-4"  autocomplete v-model="Id_MatiereOG3">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Matiere}`" v-for="(item,index) in matiereVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Indice" class="w-full mt-4"  autocomplete v-model="Id_IndiceOG3" @change="filterVerreDataOG3()">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.indice}`" v-for="(item,index) in indiceVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Option" class="w-full mt-4"  autocomplete v-model="Id_OptionVerreOG3" @change="setOptionOGPrice3(Id_OptionVerreOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_version}`" v-for="(item,index) in versionVerreData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Traitement" class="w-full mt-4"  autocomplete v-model="Id_TraitementOG3" @change="setTraitementOGPrice3(Id_TraitementOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Traitement}`" v-for="(item,index) in traitementsData" />
                  </vs-select>
                </div>
                <div class="vx-col md:w-1/2">
                  <vs-select label="Fabrication" class="w-full mt-4"  autocomplete v-model="Id_FabricationOG3" @change="setFabricationOGPrice3(Id_FabricationOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Fabrication}`" v-for="(item,index) in fabricationData" />
                  </vs-select>
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col md:w-1/2">
                  <vs-select label="Coloration" class="w-full mt-4"  autocomplete v-model="Id_ColorationOG3" @change="setColorationOGPrice3(Id_ColorationOG3)">
                    <vs-select-item :key="index" :value="item._id" :text="`${item.Libelle_Coloration}`" v-for="(item,index) in colorationData" />
                  </vs-select>
                </div>
                <div v-if="(IdFourVerrOG3 || Id_FoyerOG3 || IdGammeVerrOG3 || Id_MatiereOG3 || Id_IndiceOG3 || Id_OptionVerreOG3 || Id_TraitementOG3 || Id_FabricationOG3 || Id_ColorationOG3)" class="vx-col md:w-1/2">
                  <vs-button color="danger" class="w-full mt-10" type="filled" @click="IdFourVerrOG3=undefined;Id_FoyerOG3=undefined;IdGammeVerrOG3=undefined;Id_MatiereOG3=undefined;Id_IndiceOG3=undefined;Id_OptionVerreOG3=undefined;Id_TraitementOG3=undefined;Id_FabricationOG3=undefined;Id_ColorationOG3=undefined">Réinitialiser</vs-button>
                </div>
              </div>
            </template>
          </vx-card>
          <div class="vx-row flex justify-end mt-5">
            <div class="vx-col">
              <vs-button color="danger" class="w-full" @click="popupCatalogue3=false">Annuler</vs-button>
            </div>
            <div class="vx-col">
              <vs-button color="primary" class="w-full" @click="prixVerreOD3();prixVerreOG3();popupCatalogue3=false">Valider</vs-button>
            </div>
          </div>

        </div>
      </div>

    </vs-popup>

    <vs-popup fullscreen title="Ajouter un prescripteur" :active.sync="popoupActivePescripteur">
      <div class="vx-row">
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Centre Hospitalier">
            <div class="vx-row">
              <div class="vx-col md:w-1/2">
                <vs-input label="Raison Sociale" :value="RaisonSoc" @change.native="RaisonSoc=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Adresse"  :value="AdrCli" @change.native="AdrCli=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input type="email" :success="MailCli? true : false" success-text="Mail valide" label="Email" :value="MailCli" @change.native="MailCli=$event.target.value"  class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 1" :value="TelCli1" @change.native="TelCli1=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 2" :value="TelCli2" @change.native="TelCli2=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel 3" :value="TelCli3" @change.native="TelCli3=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Mobile 1" :value="MobCli1" @change.native="MobCli1=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Mobile 2" :value="MobCli2" @change.native="MobCli2=$event.target.value" class="w-full mt-5" />
              </div>
              <div class="vx-col md:w-1/2">
                <vs-input label="Tel Fax" :value="FaxCli" @change.native="FaxCli=$event.target.value" class="w-full mt-5" />
              </div>
            </div>
            <div class="vx-col mt-5">
              <vs-button color="success" class="w-full" @click="SaveCentreHospitalier">Enregister</vs-button>
            </div>
          </vx-card>

        </div>
        <div class="vx-col md:w-1/2">
          <vx-card :noShadow="true" :cardBorder="true" title="Prescripteur">
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Nom" :value="NomPresc" @change.native="NomPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Prenoms" :value="PrenomPresc" @change.native="PrenomPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                <vs-select autocomplete label="centre hospitalier" class="w-full" v-model="IdCenterHospi" >
                  <vs-select-item :key="index" :value="item._id" :text="item.RaisonSoc" v-for=" (item, index ) in centreHospitaliers" />
                </vs-select>
              </div>

              <div class="vx-col md:w-1/2 w-full md:mt-8">
                <div class="demo-alignment">
                  <span>Ristourne:</span>
                  <div class="flex">
                    <vs-checkbox v-model="Ristourne"></vs-checkbox>
                    <vs-input-number class="ml-8" :value="taux" @change.native="taux=$event.target.value" label="taux:"/>
                  </div>
                </div>
              </div>
            </div>
            <div class="vx-row">
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Adresse" :value="AdrPresc" @change.native="AdrPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input type="email" :success="EmailPresc ? true : false" success-text="Mail valide" label="Email" :value="EmailPresc" @change.native="EmailPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Tel" :value="TelPresc" @change.native="TelPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Mobile" :value="Mobile" @change.native="Mobile=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="Fax" :value="FaxPresc" @change.native="FaxPresc=$event.target.value" class="w-full" />
              </div>
              <div class="vx-col md:w-1/2 w-full mt-5">
                  <vs-input label="RemarqueP"  :value="RemarqueP" @change.native="RemarqueP=$event.target.value" class="w-full" />
              </div>
            </div>
            <div class="vx-row mt-5">
              <div class="vx-col w-full">
                <vs-button color="success" class="w-full" @click="SavePrescripteur" >Enregister</vs-button>
              </div>
            </div>
          </vx-card>
        </div>
      </div>
    </vs-popup>

    <div class="vx-row">
      <div class="vx-col md:w-1/3">
        <vx-card class="w-full">
          <h5>Proforma</h5>
          <div class="m-5 p-5" style="border-left: 1px dashed gray ;">

            <div class="vx-row">
              <div class="vx-col w-full">
                <img :src="getLogoUrl" alt="groupe-logo" height="100px" />
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col md:w-2/3">
        <vx-card class="w-full">
          <div class="vx-row w-full flex flex-col justify-center">
            <div class="vx-col w-full">
              <label class="typo__label" for="client">Client</label>
              <multiselect v-model="client" id="client" label="Nom" :custom-label="fullNameClient" track-by="_id" placeholder="Rechercher un client" open-direction="bottom" :options="clients" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="" selectedLabel="" deselectLabel="" :showNoOptions="false" @search-change="getClientList" @select="updateClientInfo">
              </multiselect>
            </div>
            <div class="vx-col w-full" v-if="client">
                <p>Civilité: {{client ? client.Civilite : ''}}</p>
                <p>Nom: {{client ? client.Nom : ''}}</p>
                <p>Prenom: {{client ? client.Prenoms : ''}}</p>
                <p>Adresse: {{client ? client.Adresse.mobile : ''}} {{client ? `/ ${client.Adresse.tel}` : ''}}</p>
                <p>Email: {{client ? client.Adresse.email : ''}}</p>
            </div>
          </div>
          <vs-row vs-type="flex" vs-justify="center">
             <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="12" v-if="client">
                <vx-tooltip text="Supprimer le client" position="top"> <vs-button type="gradient" color="dark" icon-pack="feather" icon="icon-trash-2" @click="client=null"></vs-button> </vx-tooltip>
             </vs-col>
             <vs-col v-if="!client" class="mt-5"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
              <h1>OU</h1>
             </vs-col>
             <vs-col v-if="!client" class="mt-5"  vs-type="flex" vs-justify="center" vs-align="center" vs-w="12">
               <vs-button icon-pack="feather" icon="icon-plus" color="primary" type="filled" @click="popupActiveClient=true">Ajouter un nouveau client</vs-button>
             </vs-col>
          </vs-row>
          <div class="vx-row">
            <div class="vx-col w-full">
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col md:w-1/4">
              <label for="DateCommd">Date*</label>
              <datepicker v-model="DateCommd" :language="languages[language]" format="d MMMM yyyy" name="DateCommd"></datepicker>
            </div>
            <div class="vx-col md:w-1/4">
              <vs-select label="Prescripteur" autocomplete class="selectExample w-full select-large" v-model="Idprescripteur" @change="goToNewPrescripteur">
                <vs-select-item value="new_prescripteur" text="Nouveau" />
                <vs-divider/>
                <vs-select-item :key="index" :value="item._id" :text="`${item.NomPresc} ${item.PrenomPresc ? item.PrenomPresc : ''}`" v-for="(item,index) in prescripteurs" />
              </vs-select>
            </div>
            <div class="vx-col md:w-1/4">
              <vs-select label="Ordonnances" autocomplete class="selectExample w-full select-large" v-model="RefOrd" @change="OrdonnanceValue">
                <vs-select-item :key="index" :value="item.RefOrd" :text="item.RefOrd" v-for="(item,index) in ordonnances" />
              </vs-select>
            </div>
          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full">
              <h2>Prescription</h2>
            </div>
            <vs-divider/>
            <div class="vx-col md:3/6">
              <div class="vx-row">
                <div class="vx-col mt-8">
                  <span class="font-black">OD</span>
                  <br /><br />
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAllPrescriptionODToOG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVD" :value="SphVD"  @change.native="FormateSPHOD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopySphVDToSphVG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVD" :value="CylVD"  @change.native="FormateCYLOD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyCylVDToCylVG()" />
                </div>
                <div class="vx-col  text-center">
                  <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVD" :value="AxeVD" @change.native="AxeVD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAxeVDToAxeVG()" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVD" :value="AddVD" @change.native="FormateAddVD($event.target.value)" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyAddVDToAddVG()" />
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col mt-8">
                  <span class="font-black">OG</span>
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="SPH"  name="SphVG" :value="SphVG" @change.native="FormateSPHOG($event.target.value)" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="CYL"  name="CylVG" :value="CylVG"  @change.native="FormateCYLOG($event.target.value)" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="AXE"  name="AxeVG" :value="AxeVG" @change.native="AxeVG=$event.target.value" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ADDITION"  name="AddVG" :value="AddVG" @change.native="FormateAddVG($event.target.value)" />
                </div>
              </div>
            </div>
            <div class="vx-col md:2/6">
              <div class="vx-row">
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VL"  name="EcartVlOD" :value="EcartVlOD" @change.native="EcartVlOD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyEcartVlODToOG()" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VP"  name="EcartVpOD" :value="EcartVpOD" @change.native="EcartVpOD=$event.target.value" />
                  <br>
                  <feather-icon
                  icon="ArrowDownIcon"
                  class="cursor-pointer"
                  @click="CopyEcartVpODToOG()" />
                </div>
              </div>
              <div class="vx-row">
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VL"  name="EcartVlOG" :value="EcartVlOG" @change.native="EcartVlOG=$event.target.value" />
                </div>
                <div class="vx-col text-center">
                  <vs-input class="w-full" size="small" type="text" label="ECART VP"  name="EcartVPOG" :value="EcartVpOG" @change.native="EcartVpOG=$event.target.value" />
                </div>
              </div>
            </div>

          </div>
        </vx-card>
      </div>
    </div>
    <div class="vx-row mt-5">
      <div class="vx-col w-full">
        <vx-card>
          <div class="vx-row">
            <div class="vx-col w-full">
              <h2>Montures</h2>
            </div>
            <vs-divider/>
            <div class="vx-col w-full">
              <div class="vx-row">
                <div class="vx-col w-full">
                  <h4>N°1</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont1"  @change="OrigineMontureFunction(OrigineMont1)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelect" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture1" id="monture1" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures1" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock1" @select="onSuggestSelectArticle1">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInput" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient" :value="libelleMontureClient1" @change.native="libelleMontureClient1=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" label="Hauteur Oeil droit"  name="Ht_Mont1_OD" :value="Ht_Mont1_OD" @change.native="Ht_Mont1_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" label="Hauteur Oeil gauche"  name="Ht_Mont1_OG" :value="Ht_Mont1_OG" @change.native="Ht_Mont1_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont1"  :value="PrixMont1" @change.native="PrixMont1=$event.target.value"/>
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD1" :value="RefVerD1" @change.native="RefVerD1=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG1" :value="RefVerG1" @change.native="RefVerG1=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD1" :value="PrixVerD1" @change.native="PrixVerD1=$event.target.value"/>
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD1ToRefVerG1()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG1" :value="PrixVerG1" @change.native="PrixVerG1=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4>N°2</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont2" @change="OrigineMontureProposition2(OrigineMont2)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelectP2" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture2" id="monture2" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures2" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock2" @select="onSuggestSelectArticle2">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInputP2" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient2" :value="libelleMontureClient2" @change.native="libelleMontureClient2=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil droit"  name="Ht_Mont2_OD" :value="Ht_Mont2_OD" @change.native="Ht_Mont2_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil gauche"  name="Ht_Mont2_OG" :value="Ht_Mont2_OG" @change.native="Ht_Mont2_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont2" :value="PrixMont2" @change.native="PrixMont2=$event.target.value" />
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD2" :value="RefVerD2" @change.native="RefVerD2=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG2" :value="RefVerG2" @change.native="RefVerG2=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue2=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD2" :value="PrixVerD2" @change.native="PrixVerD2=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD2ToRefVerG2()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG2" :value="PrixVerG2" @change.native="PrixVerG2=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div class="vx-col w-full mt-5">
                  <h4>N°3</h4>
                </div>
                <div class="vx-col w-full p-10 mt-5" style="background: #FAFAFA">
                  <div class="vx-row">
                    <div class="vx-col md:w-1/5">
                      <vs-select label="Origine monture" autocomplete class="selectExample w-full select-large" v-model.lazy="OrigineMont3" @change="OrigineMontureProposition3(OrigineMont3)">
                        <vs-select-item :key="index" :value="item.value" :text="item.text" v-for="(item,index) in origineMonture" />
                      </vs-select>
                    </div>
                    <div v-if="showMontureStockSelectP3" class="vx-col md:w-1/5">
                      <label>Monture</label>
                      <multiselect v-model="monture3" id="monture3" label="Etiquette" :custom-label="fullMontureName" track-by="_id" placeholder="Etiquette" open-direction="bottom" :options="montures3" :searchable="true" :loading="isLoading" :internal-search="false" :clear-on-select="false" :close-on-select="false" :options-limit="300" :limit="3" :max-height="600" :show-no-results="false" selectLabel="Entrée pour sélectionner" selectedLabel="Sélectionné" deselectLabel="Entrée pour supprimer" @search-change="getArticleInstock3" @select="onSuggestSelectArticle3">
                      </multiselect>
                    </div>
                    <div v-if="showMontureClientInputP3" class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Monture"  name="libelleMontureClient3" :value="libelleMontureClient3" @change.native="libelleMontureClient3=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil droit"  name="Ht_Mont3_OD" :value="Ht_Mont3_OD" @change.native="Ht_Mont3_OD=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="text" label="Hauteur Oeil gauche"  name="Ht_Mont3_OG" :value="Ht_Mont3_OG" @change.native="Ht_Mont3_OG=$event.target.value" />
                    </div>
                    <div class="vx-col md:w-1/5">
                      <vs-input class="w-full" type="number" min="0" label="Prix Monture"  name="PrixMont3" :value="PrixMont3" @change.native="PrixMont3=$event.target.value" />
                    </div>
                  </div>

                  <div class="flex">
                    <div class="w-3/5">
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OD</span>
                          <br /><br />
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                        <div class="w-full  text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerD3" :value="RefVerD3" @change.native="RefVerD3=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="mr-2 mt-8">
                          <span class="font-black">OG</span>
                        </div>
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="text" label="Référence verre"  name="RefVerG3" :value="RefVerG3" @change.native="RefVerG3=$event.target.value" />
                        </div>
                      </div>
                    </div>
                    <div class="ml-2 mr-2">
                      <div class="vx-row">
                        <div class="vx-col w-full text-center pt-24">
                          <feather-icon
                          icon="SearchIcon"
                          class="cursor-pointer"
                          @click="popupCatalogue3=true" />
                        </div>
                      </div>
                    </div>
                    <div class="w-2/5">
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerD3" :value="PrixVerD3" @change.native="PrixVerD3=$event.target.value" />
                          <br>
                          <feather-icon
                          icon="ArrowDownIcon"
                          class="cursor-pointer"
                          @click="CopyRefVerD3ToRefVerG3()" />
                        </div>
                      </div>
                      <div class="flex">
                        <div class="w-full text-center">
                          <vs-input class="w-full" type="number" label="Prix"  name="PrixVerG3" :value="PrixVerG3" @change.native="PrixVerG3=$event.target.value" />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </vx-card>
      </div>
      <div class="vx-col w-full mt-5">
        <vx-card :collapseAction="true">
          <div class="vx-row">
            <div class="vx-col w-full">
              <h4>Remise globale</h4>
            </div>
            <vs-divider/>
          </div>
          <vs-row vs-type="flex" vs-justify="center">
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <div class="w-full">
                <label for="Remarque">Remarque</label>
                <vs-textarea name="Remarque" :value="Remarque" @change.native="Remarque=$event.target.value" />
              </div>
            </vs-col>
            <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="4">
              <div class="">
                <label for="numFacture">Remise</label>
                <vs-input type="number" class="w-full" :value="RemiseCom" @change.native="ConvertRemise($event.target.value)" />
              </div>
            </vs-col>
          </vs-row>
        </vx-card>
      </div>
      <div class="vx-col w-full mt-5" style="background: #808080;color:#FAFAFA;position: fixed;bottom:8px;z-index: 1000">
        <vs-row vs-type="flex" vs-justify="center">
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="3">
            <div>
              <div style="border-bottom: 1px dashed #FAFAFA;">
                Total
              </div>
              <h2 style="color:#FAFAFA">{{moneyFormatter(calculNet)}} FCFA HT</h2>
            </div>
          </vs-col>
          <vs-col vs-type="flex" vs-justify="center" vs-align="center" vs-w="2">
            <vs-button color="success" type="gradient" icon-pack="feather" icon="icon-check" @click="SaveProforma">Valider</vs-button>
          </vs-col>

        </vs-row>
      </div>
    </div>
  </div>
</template>

<script>
const flatPickr = () => import('vue-flatpickr-component')
import 'flatpickr/dist/flatpickr.css'
const Datepicker = () => import('vuejs-datepicker')
import * as lang from 'vuejs-datepicker/src/locale'
import draggable from 'vuedraggable'
import Multiselect from 'vue-multiselect'
import axios from '@/axios.js'

export default {
  name: 'Add',
  components: {
    flatPickr,
    Datepicker,
    draggable,
    Multiselect
  },
  data () {
    return {
      isLoading: false,

      activeUserInfos: null,
      ordonnances: null,
      RefOrd: null,
      dateProformat: null,
      clients: [],
      client: null,
      IdOfficine: null,
      IdClient: null,
      Idprescripteur: null,
      IdUser: null,
      IdPartenaire: null,
      DatePrescrit: null,
      DateCommd: null,
      SphVD: '',
      SphVG: '',
      CylVD: '',
      CylVG: '',
      AxeVD: '',
      AxeVG: '',
      AddVD: '',
      AddVG: '',

      Ht_Mont1_OD: null,
      Ht_Mont1_OG: null,
      Dctr_Mont1: null,
      EcartVlOD: null,
      EcartVpOD: null,
      EcartVlOG: null,
      EcartVpOG: null,

      Ht_Mont2_OD: null,
      Ht_Mont2_OG: null,
      Ht_Mont3_OD: null,
      Ht_Mont3_OG: null,
      Dctr_Mont2: null,
      Dctr_Mont3: null,

      montures1: [],
      IdMont1: null,
      monture1: null,
      libelleMontureClient1: '',
      OrigineMont1: '',
      PrixMont1: 0,
      RefVerG1: '',
      PrixVerG1: 0,
      RefVerD1: '',
      PrixVerD1: 0,
      Suplement1: '',

      montures2: [],
      IdMont2: null,
      monture2: null,
      libelleMontureClient2: '',
      OrigineMont2: '',
      PrixMont2: 0,
      RefVerG2: '',
      PrixVerG2: 0,
      RefVerD2: '',
      PrixVerD2: 0,
      Suplement2: '',

      montures3: [],
      IdMont3: null,
      monture3: null,
      libelleMontureClient3: '',
      OrigineMont3: '',
      PrixMont3: 0,
      RefVerG3: '',
      PrixVerG3: 0,
      RefVerD3: '',
      PrixVerD3: 0,
      Suplement3: '',

      Remarque: '',
      TotalFactBrut: 0,
      RemiseCom: 0,
      TotalFactNet: 0,
      PartClient: 0,
      PartAssu: 0,
      PECharge: false,
      CmdeTSalleMontage: false,
      CmdeAuFourVerre: false,
      CmdeMontageFin: false,
      ComdeLivreClient: false,
      TypeMontureOptiq: false,
      propositions: [],
      proposition1: false,
      proposition2: false,
      proposition3: false,
      popupActive: false,
      popoupActivePescripteur: false,
      origineMonture: [
        {
          text: 'Stock',
          value: 'stock'
        },
        {
          text: 'Monture Client',
          value: 'monture_client'
        },
        {
          text: 'Autre',
          value: 'autre'
        }
      ],
      showMontureStockSelect: true,
      showMontureClientInput: false,

      showMontureStockSelectP2: true,
      showMontureClientInputP2: false,

      showMontureStockSelectP3: true,
      showMontureClientInputP3: false,

      showCatalogueSelectVerD1: false,
      showCatalogueSelectVerG1: false,
      showCatalogueSelectVerG2: false,
      showCatalogueSelectVerD2: false,
      catalogueVerreG1: '',
      catalogueVerreD1: '',
      prevRoute: null,

      IdCenterHospi:'',
      NomPresc: '',
      PrenomPresc:'',
      Ristourne: false,
      taux:0,
      TelPresc: null,
      Mobile: null,
      FaxPresc: null,
      AdrPresc: null,
      EmailPresc: null,
      RemarqueP: null,

      RaisonSoc: null,
      AdrCli: null,
      MailCli: null,
      TelCli1: null,
      TelCli2: null,
      TelCli3: null,
      MobCli1: null,
      MobCli2: null,
      FaxCli: null,

      montureClearName1: null,
      montureClearName2: null,
      montureClearName3: null,

      active: false,

      /* PDF Config params */
      showlayout: 'false',
      floatlayout: 'true',
      enabledownload: 'false',
      previewmodal: 'true',
      filename: 'Proforma',
      pdfquality: '2',
      manualpagination: 'false',
      pdfformat: 'a4',
      pdforientation: 'landscape',
      pdfcontentwidth: '100%',
      /* new clients */
      popupActiveClient: false,
      civilites: [
        {
          text: 'Prof',
          value: 'Prof'
        },
        {
          text: 'Dr',
          value: 'Dr'
        },
        {
          text: 'M',
          value: 'M'
        },
        {
          text: 'Mme',
          value: 'Mme'
        },
        {
          text: 'Mlle',
          value: 'Mlle'
        },
        {
          text: 'Enft',
          value: 'Enft'
        }
      ],
      Civilite: null,
      Nom: null,
      Prenoms: null,
      Alias: null,
      Profession: null,
      DateNaiss: null,
      adresse: null,
      email: null,
      pays: null,
      ville: null,
      quartier: null,
      localite: null,
      tel: null,
      mobile: null,
      fax: null,
      cp: null,
      Activitextra: null,
      Langue: null,
      remarque: null,
      CarteFidelite: false,
      Assure: false,
      DCD: false,
      ChefFamille: false,
      PasSMS: false,
      PasMail: false,
      language: 'fr',
      languages: lang,
      /* catalogue verre */
      dataModelVerres: [],
      dataModelVerresOG1: [],
      dataMatricePrixOD1: [],
      dataMatricePrixOG1: [],

      dataModelVerresOD2: [],
      dataModelVerresOG2: [],
      dataMatricePrixOD2: [],
      dataMatricePrixOG2: [],

      dataModelVerresOD3: [],
      dataModelVerresOG3: [],
      dataMatricePrixOD3: [],
      dataMatricePrixOG3: [],

      btnODColor: 'primary',
      btnOGColor: 'lightgray',
      showODCommandeVerre: true,
      showOGCommandeVerre: false,

      showODCommandeVerre2: true,
      showOGCommandeVerre2: false,

      showODCommandeVerre3: true,
      showOGCommandeVerre3: false,

      oldCataloguePriceData: [],
      CataloguePrixData: [],
      cataloguePrixDataFilter: [],

      popupCatalogue: false,
      popupCatalogue2: false,
      popupCatalogue3: false,

      Id_FoyerOD1: undefined,
      IdGammeVerrOD1: undefined,
      Id_OptionVerreOD1: undefined,
      Prix_OptionVerreOD1: 0,
      Id_MatiereOD1: undefined,
      Id_IndiceOD1: undefined,
      IdFourVerrOD1: undefined,
      Id_FabricationOD1: undefined,
      Prix_FabricationOD1: 0,
      Id_ColorationOD1: undefined,
      Prix_ColorationOD1: 0,
      Id_TraitementOD1: undefined,
      Prix_TraitementOD1: 0,

      Id_FoyerOG1: undefined,
      IdGammeVerrOG1: undefined,
      Id_OptionVerreOG1: undefined,
      Prix_OptionVerreOG1: 0,
      Id_MatiereOG1: undefined,
      Id_IndiceOG1: undefined,
      IdFourVerrOG1: undefined,
      Id_FabricationOG1: undefined,
      Prix_FabricationOG1: 0,
      Id_ColorationOG1: undefined,
      Prix_ColorationOG1: 0,
      Id_TraitementOG1: undefined,
      Prix_TraitementOG1: 0,

      Id_FoyerOD2: undefined,
      IdGammeVerrOD2: undefined,
      Id_OptionVerreOD2: undefined,
      Prix_OptionVerreOD2: 0,
      Id_MatiereOD2: undefined,
      Id_IndiceOD2: undefined,
      IdFourVerrOD2: undefined,
      Id_FabricationOD2: undefined,
      Prix_FabricationOD2: 0,
      Id_ColorationOD2: undefined,
      Prix_ColorationOD2: 0,
      Id_TraitementOD2: undefined,
      Prix_TraitementOD2: 0,

      Id_FoyerOG2: undefined,
      IdGammeVerrOG2: undefined,
      Id_OptionVerreOG2: undefined,
      Prix_OptionVerreOG2: 0,
      Id_MatiereOG2: undefined,
      Id_IndiceOG2: undefined,
      IdFourVerrOG2: undefined,
      Id_FabricationOG2: undefined,
      Prix_FabricationOG2: 0,
      Id_ColorationOG2: undefined,
      Prix_ColorationOG2: 0,
      Id_TraitementOG2: undefined,
      Prix_TraitementOG2: 0,

      Id_FoyerOD3: undefined,
      IdGammeVerrOD3: undefined,
      Id_OptionVerreOD3: undefined,
      Prix_OptionVerreOD3: 0,
      Id_MatiereOD3: undefined,
      Id_IndiceOD3: undefined,
      IdFourVerrOD3: undefined,
      Id_FabricationOD3: undefined,
      Prix_FabricationOD3: 0,
      Id_ColorationOD3: undefined,
      Prix_ColorationOD3: 0,
      Id_TraitementOD3: undefined,
      Prix_TraitementOD3: 0,
      
      Id_FoyerOG3: undefined,
      IdGammeVerrOG3: undefined,
      Id_OptionVerreOG3: undefined,
      Prix_OptionVerreOG3: 0,
      Id_MatiereOG3: undefined,
      Id_IndiceOG3: undefined,
      IdFourVerrOG3: undefined,
      Id_FabricationOG3: undefined,
      Prix_FabricationOG3: 0,
      Id_ColorationOG3: undefined,
      Prix_ColorationOG3: 0,
      Id_TraitementOG3: undefined,
      Prix_TraitementOG3: 0

    }
  },
  computed: {
    typeVerreData () {
      return this.$store.state.verre.types_verre
    },
    matiereVerreData () {
      return this.$store.state.verre.matieres_verres
    },
    indiceVerreData () {
      return this.$store.state.verre.indices
    },
    fournisseur_verres_data () {
      return this.$store.state.verre.fournisseurs_verres
    },
    gammeVerresData () {
      return this.$store.getters['verre/gammeverres']
    },
    versionVerreData () {
      return this.$store.getters['verre/options']
    },
    traitementsData () {
      return this.$store.getters['verre/traitements']
    },
    fabricationData () {
      return this.$store.getters['verre/fabrications']
    },
    colorationData () {
      return this.$store.getters['verre/colorations']
    },
    clientsData () {
      return this.$store.state.client.clients
    },
    prescripteurs () {
      return this.$store.state.prescripteur.prescripteurs
    },
    centreHospitaliers () {
      return this.$store.state.centre_hospitalier.centre_hospitaliers
    },
    villeData () {
      return this.$store.state.pays.villes
    },
    paysData () {
      return this.$store.state.pays.pays
    },
    quartierData () {
      return this.$store.state.pays.quartiers
    },
    getLogoUrl () {
      return this.$store.getters['entete_piedpage/getLogoUrl']
    },
    getPiedpageUrl () {
      return this.$store.getters['entete_piedpage/getPiedpageUrl']
    },
    calculNet () {
      return (((+this.PrixMont1 + +this.PrixVerG1 + +this.PrixVerD1) +  (+this.PrixMont2 + +this.PrixVerG2 + +this.PrixVerD2) +  (+this.PrixMont3 + +this.PrixVerG3 + +this.PrixVerD3)) - +this.RemiseCom).toFixed(0)
    }

  },
  methods: {
    getClientList (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/client-full-search/${this.IdOfficine}/${inputValue}`)
          .then((response) => {
            this.clients = response.data
            this.isLoading = false
            /* resolve(response) */
          })
          .catch((err) => {
            reject(err)
            this.isLoading = false
          })

      })
    },
    fullNameClient ({ Nom, Prenoms }) {
      return `${Nom} ${Prenoms}`
    },
    updateClientInfo (client) {
      this.ordonnances = client.ordonnances
      this.priseEnCharges = client.priseEnCharges
      this.IdClient = client._id
    },
    fullMontureName ({ Etiquette, IdMarque}) {
      return `${Etiquette ? Etiquette : ''} | ${this.getMarqueName(IdMarque)}`
    },
    getArticleInstock1 (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures1 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle1 (monture) {
      this.PrixMont1 = monture.PrixVenteTTC
      this.IdMont1 = monture._id
      this.montureClearName1 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    getArticleInstock2 (inputValue) {
      this.isLoading = true
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures2 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle2 (monture) {
      this.PrixMont2 = monture.PrixVenteTTC
      this.IdMont2 = monture._id
      this.montureClearName2 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    getArticleInstock3 (inputValue) {
      return new Promise((resolve, reject) => {
        axios.get(`/api/stock/article-etiquette/${inputValue}/${this.IdOfficine}`)
          .then((response) => {
            this.montures3 = response.data
            this.isLoading = false
            resolve(response)
          })
          .catch((err) => {
            this.isLoading = false
            reject(err)
          })
      })
    },
    onSuggestSelectArticle3 (monture) {
      this.PrixMont3 = monture.PrixVenteTTC
      this.IdMont3 = monture._id
      this.montureClearName3 = `${monture.Etiquette} | ${this.getMarqueName(monture.IdMarque)} ${monture.Modele} ${monture.Couleur}`
    },
    ConvertRemise (data) {
      if (+data <= 100) {
        const montant = ((this.calculNet * (+data)) / 100).toFixed(0)
        this.RemiseCom = montant
      } else {
        this.RemiseCom = data
      }
    },
    showMatiereName (id) {
      return this.$store.getters['verre/showMatiereName'](id)
    },
    setOptionODPrice1 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD1,
        indice: this.Id_IndiceOD1
      }
      const base = this.IdGammeVerrOD1 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD1 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD1) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD1 && this.IdGammeVerrOD1) {
        this.RefVerD1 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice1 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD1 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice1 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD1 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice1 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD1 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD1 () {
      this.PrixVerD1 = (+this.Prix_OptionVerreOD1 + +this.Prix_TraitementOD1 + +this.Prix_FabricationOD1 + +this.Prix_ColorationOD1)
    },

    setOptionOGPrice1 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG1,
        indice: this.Id_IndiceOG1
      }
      const base = this.IdGammeVerrOG1 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG1 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG1 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG1) : ''
      this.RefVerG1 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice1 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG1 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice1 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG1 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice1 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG1 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG1 () {
      this.PrixVerG1 = (+this.Prix_OptionVerreOG1 + +this.Prix_TraitementOG1 + +this.Prix_FabricationOG1 + +this.Prix_ColorationOG1)
    },

    setOptionODPrice2 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD2,
        indice: this.Id_IndiceOD2
      }
      const base = this.IdGammeVerrOD2 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD2 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD2) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD2 && this.IdGammeVerrOD2) {
        this.RefVerD2 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice2 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD2 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice2 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD2 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice2 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD2 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD2 () {
      this.PrixVerD2 = (+this.Prix_OptionVerreOD2 + +this.Prix_TraitementOD2 + +this.Prix_FabricationOD2 + +this.Prix_ColorationOD2)
    },

    setOptionOGPrice2 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG2,
        indice: this.Id_IndiceOG2
      }
      const base = this.IdGammeVerrOG2 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG2 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG2 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG2) : ''
      this.RefVerG2 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice2 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG2 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice2 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG2 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice2 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG2 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG2 () {
      this.PrixVerG2 = (+this.Prix_OptionVerreOG2 + +this.Prix_TraitementOG2 + +this.Prix_FabricationOG2 + +this.Prix_ColorationOG2)
    },

    setOptionODPrice3 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOD3,
        indice: this.Id_IndiceOD3
      }
      const base = this.IdGammeVerrOD3 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOD3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOD3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOD3 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOD3) : ''
      //console.log('option:', option)
      if (this.IdFourVerrOD3 && this.IdGammeVerrOD3) {
        this.RefVerD3 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
      }
    },
    setTraitementODPrice3 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOD3 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationODPrice3 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOD3 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationODPrice3 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOD3 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOD3 () {
      this.PrixVerD3 = (+this.Prix_OptionVerreOD3 + +this.Prix_TraitementOD3 + +this.Prix_FabricationOD3 + +this.Prix_ColorationOD3)
    },

    setOptionOGPrice3 (id) {
      const option = this.$store.getters['verre/getOptionById'](id)
      const payload = {
        gamme: this.IdGammeVerrOG3,
        indice: this.Id_IndiceOG3
      }
      const base = this.IdGammeVerrOG3 ? this.$store.getters['verre/getOptionByGammeIdAndBase'](payload) : null
      if (base._id === id) {
        this.Prix_OptionVerreOG3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (option.Prix_achat_version + option.Majore_Prix_Base)) : 0
      } else {
        this.Prix_OptionVerreOG3 = option ? (option.Prix_vente_version ? option.Prix_vente_version : (base.Prix_vente_version + option.Majore_Prix_Base)) : 0
      }
      const showGammeName = this.IdGammeVerrOG3 ? this.$store.getters['verre/showGammeName'](this.IdGammeVerrOG3) : ''
      this.RefVerG3 = `${  showGammeName  } ${option ? option.Libelle_indice_Four : ''  } ${option ? option.Libelle_version : ''}`
    },
    setTraitementOGPrice3 (id) {
      const traitement = this.$store.getters['verre/getTraitementById'](id)
      this.Prix_TraitementOG3 = traitement ? traitement.prix_Traitement : 0
    },
    setFabricationOGPrice3 (id) {
      const fabrication = this.$store.getters['verre/getFabricationById'](id)
      this.Prix_FabricationOG3 = fabrication ? fabrication.Prix_vente_Fabrication : 0
    },
    setColorationOGPrice3 (id) {
      const coloration = this.$store.getters['verre/getColorationById'](id)
      this.Prix_ColorationOG3 = coloration ? coloration.Prix_vente_Coloration : 0
    },
    prixVerreOG3 () {
      this.PrixVerG3 = (+this.Prix_OptionVerreOG3 + +this.Prix_TraitementOG3 + +this.Prix_FabricationOG3 + +this.Prix_ColorationOG3)
    },

    filterVerreDataOD1 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD1,
        IdFoyer: this.Id_FoyerOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined && this.Id_FoyerOD1 !== undefined) ? 2 : ((this.IdFourVerrOD1 !== undefined || this.Id_FoyerOD1 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD1,
        IdGam: this.IdGammeVerrOD1,
        IdIndice: this.Id_IndiceOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined && this.IdGammeVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined) ? 3 : (((this.IdFourVerrOD1 !== undefined && this.IdGammeVerrOD1 !== undefined) || (this.IdFourVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined) || (this.IdGammeVerrOD1 !== undefined && this.Id_IndiceOD1 !== undefined)) ? 2 : (this.IdFourVerrOD1 !== undefined || this.IdGammeVerrOD1 !== undefined || this.Id_IndiceOD1 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD1,
        filterCount: (this.IdFourVerrOD1 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG1 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG1,
        IdFoyer: this.Id_FoyerOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined && this.Id_FoyerOG1 !== undefined) ? 2 : ((this.IdFourVerrOG1 !== undefined || this.Id_FoyerOG1 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG1,
        IdGam: this.IdGammeVerrOG1,
        IdIndice: this.Id_IndiceOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined && this.IdGammeVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined) ? 3 : (((this.IdFourVerrOG1 !== undefined && this.IdGammeVerrOG1 !== undefined) || (this.IdFourVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined) || (this.IdGammeVerrOG1 !== undefined && this.Id_IndiceOG1 !== undefined)) ? 2 : (this.IdFourVerrOG1 !== undefined || this.IdGammeVerrOG1 !== undefined || this.Id_IndiceOG1 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG1,
        filterCount: (this.IdFourVerrOG1 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },

    filterVerreDataOD2 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD2,
        IdFoyer: this.Id_FoyerOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined && this.Id_FoyerOD2 !== undefined) ? 2 : ((this.IdFourVerrOD2 !== undefined || this.Id_FoyerOD2 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD2,
        IdGam: this.IdGammeVerrOD2,
        IdIndice: this.Id_IndiceOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined && this.IdGammeVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined) ? 3 : (((this.IdFourVerrOD2 !== undefined && this.IdGammeVerrOD2 !== undefined) || (this.IdFourVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined) || (this.IdGammeVerrOD2 !== undefined && this.Id_IndiceOD2 !== undefined)) ? 2 : (this.IdFourVerrOD2 !== undefined || this.IdGammeVerrOD2 !== undefined || this.Id_IndiceOD2 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD2,
        filterCount: (this.IdFourVerrOD2 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG2 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG2,
        IdFoyer: this.Id_FoyerOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined && this.Id_FoyerOG2 !== undefined) ? 2 : ((this.IdFourVerrOG2 !== undefined || this.Id_FoyerOG2 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG2,
        IdGam: this.IdGammeVerrOG2,
        IdIndice: this.Id_IndiceOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined && this.IdGammeVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined) ? 3 : (((this.IdFourVerrOG2 !== undefined && this.IdGammeVerrOG2 !== undefined) || (this.IdFourVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined) || (this.IdGammeVerrOG2 !== undefined && this.Id_IndiceOG2 !== undefined)) ? 2 : (this.IdFourVerrOG2 !== undefined || this.IdGammeVerrOG2 !== undefined || this.Id_IndiceOG2 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG2,
        filterCount: (this.IdFourVerrOG2 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },

    filterVerreDataOD3 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOD3,
        IdFoyer: this.Id_FoyerOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined && this.Id_FoyerOD3 !== undefined) ? 2 : ((this.IdFourVerrOD3 !== undefined || this.Id_FoyerOD3 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOD3,
        IdGam: this.IdGammeVerrOD3,
        IdIndice: this.Id_IndiceOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined && this.IdGammeVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined) ? 3 : (((this.IdFourVerrOD3 !== undefined && this.IdGammeVerrOD3 !== undefined) || (this.IdFourVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined) || (this.IdGammeVerrOD3 !== undefined && this.Id_IndiceOD3 !== undefined)) ? 2 : (this.IdFourVerrOD3 !== undefined || this.IdGammeVerrOD3 !== undefined || this.Id_IndiceOD3 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOD3,
        filterCount: (this.IdFourVerrOD3 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterVerreDataOG3 () {
      const gammefilterObj = {
        IdFourVerr: this.IdFourVerrOG3,
        IdFoyer: this.Id_FoyerOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined && this.Id_FoyerOG3 !== undefined) ? 2 : ((this.IdFourVerrOG3 !== undefined || this.Id_FoyerOG3 !== undefined) ? 1 : 0)
      }

      //console.log('gammefilterObj:', gammefilterObj)

      this.filterGamme(gammefilterObj)

      // Option verre
      const optionFilterObj = {
        IdFour: this.IdFourVerrOG3,
        IdGam: this.IdGammeVerrOG3,
        IdIndice: this.Id_IndiceOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined && this.IdGammeVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined) ? 3 : (((this.IdFourVerrOG3 !== undefined && this.IdGammeVerrOG3 !== undefined) || (this.IdFourVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined) || (this.IdGammeVerrOG3 !== undefined && this.Id_IndiceOG3 !== undefined)) ? 2 : (this.IdFourVerrOG3 !== undefined || this.IdGammeVerrOG3 !== undefined || this.Id_IndiceOG3 !== undefined) ? 1 : 0)
      }
      this.filterOptions(optionFilterObj)

      // Traitement
      const optionTraitemetObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterTraitement(optionTraitemetObj)

      // Fabrication
      const optionFabricationObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterFabrication(optionFabricationObj)
      // Coloration
      const optionColorationObj = {
        IdFour: this.IdFourVerrOG3,
        filterCount: (this.IdFourVerrOG3 !== undefined) ? 1 : 0
      }
      this.filterColoration(optionColorationObj)

    },
    filterOptions (filter) {
      this.$store.dispatch('verre/filterVersionVerre', filter)
    },
    filterGamme (filter) {
      this.$store.dispatch('verre/filterGammeVerre', filter)
    },
    filterTraitement (filter) {
      this.$store.dispatch('verre/filterTraitementVerre', filter)
    },
    filterFabrication (filter) {
      this.$store.dispatch('verre/filterFabricationVerre', filter)
    },
    filterColoration (filter) {
      this.$store.dispatch('verre/filterColorationVerre', filter)
    },

    FormateSPHOD (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVD = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateSPHOG (sph) {
      //convert string to float and fix
      const Sph = parseFloat(sph).toFixed(2)

      this.SphVG = Math.sign(Sph) === 1 || Math.sign(Sph) === 0 ? `+${Sph}` : Sph
    },
    FormateCYLOD (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVD = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateCYLOG (cyl) {
      //convert string to float and fix
      const Cyl = parseFloat(cyl).toFixed(2)

      this.CylVG = Math.sign(Cyl) === 1 || Math.sign(Cyl) === 0 ? `+${Cyl}` : Cyl
    },
    FormateAddVD (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVD = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    FormateAddVG (add) {
      //convert string to float and fix
      const Add = parseFloat(add).toFixed(2)

      this.AddVG = Math.sign(Add) === 1 || Math.sign(Add) === 0 ? `+${Add}` : Add
    },
    FormateEcartVlOD (val) {
      this.EcartVlOD = parseFloat(val).toFixed(1)
    },
    FormateEcartVlOG (val) {
      this.EcartVlOG = parseFloat(val).toFixed(1)
    },
    FormateEcartVpOD (val) {
      this.EcartVpOD = parseFloat(val).toFixed(1)
    },
    FormateEcartVpOG (val) {
      this.EcartVpOG = parseFloat(val).toFixed(1)
    },
    CopyAllPrescriptionODToOG () {
      this.SphVG = this.SphVD
      this.CylVG = this.CylVD
      this.AxeVG = this.AxeVD
      this.AddVG = this.AddVD
      this.EcartVlOG = this.EcartVlOD
      this.EcartVpOG = this.EcartVpOD
    },
    CopySphVDToSphVG () {
      this.SphVG = this.SphVD
    },
    CopyCylVDToCylVG () {
      this.CylVG = this.CylVD
    },
    CopyAxeVDToAxeVG () {
      this.AxeVG = this.AxeVD
    },
    CopyAddVDToAddVG () {
      this.AddVG = this.AddVD
    },
    CopyEcartVlODToOG () {
      this.EcartVlOG = this.EcartVlOD
    },
    CopyEcartVpODToOG () {
      this.EcartVpOG = this.EcartVpOD
    },
    CopyRefVerD1ToRefVerG1 () {
      this.RefVerG1 = this.RefVerD1
      this.PrixVerG1 = this.PrixVerD1
    },
    CopyRefVerD2ToRefVerG2 () {
      this.RefVerG2 = this.RefVerD2
      this.PrixVerG2 = this.PrixVerD2
    },
    getFournisseurVerreName (id_modele_verre) {
      const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id_modele_verre)

      if (modeleVerre.IdFourVerr) {
        return this.$store.getters['verre/showFournisseurName'](modeleVerre.IdFourVerr)
      } else {
        return ''
      }
    },
    getModeleVerreName (id_modele_verre) {
      const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id_modele_verre)
      return modeleVerre.Libelle_Model
    },
    getTypeVerreName (id_modele_verre) {
      const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id_modele_verre)

      if (modeleVerre.IdFoyer) {
        return this.$store.getters['verre/showFoyerName'](modeleVerre.IdFoyer)
      } else {
        return ''
      }

    },
    OnTypeVerreChange (id_type_verre) {
      this.CataloguePrixData = this.oldCataloguePriceData
      this.cataloguePrixDataFilter = []

      if (this.CataloguePrixData.length > 0) {

        this.CataloguePrixData.forEach(matrix_price => {
          const modeleVerre = this.$store.getters['verre/getModeleVerreById'](matrix_price.IdModelVer)
          if (modeleVerre.IdFoyer === id_type_verre) {
            this.cataloguePrixDataFilter.unshift(matrix_price)
          }
        })
      }

      this.CataloguePrixData = this.cataloguePrixDataFilter
    },
    getIndiceVerreValue (id_modele_verre) {
      const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id_modele_verre)

      if (modeleVerre.IdFoyer) {
        return this.$store.getters['verre/showIndiceName'](modeleVerre.IdIndice)
      } else {
        return ''
      }
    },
    OnIndiceVerreChange (id_indice_verre) {
      this.CataloguePrixData = this.oldCataloguePriceData
      this.cataloguePrixDataFilter = []

      if (this.CataloguePrixData.length > 0) {
        this.CataloguePrixData.forEach(matrix_price => {
          const modeleVerre = this.$store.getters['verre/getModeleVerreById'](matrix_price.IdModelVer)
          if (modeleVerre.IdIndice === id_indice_verre) {
            this.cataloguePrixDataFilter.unshift(matrix_price)
          }
        })

        this.CataloguePrixData = this.cataloguePrixDataFilter
      }
    },
    getMatiereVerreName (id_modele_verre) {
      const modeleVerre = this.$store.getters['verre/getModeleVerreById'](id_modele_verre)

      if (modeleVerre.IdFoyer) {
        return this.$store.getters['verre/showMatiereName'](modeleVerre.IdMatiere)
      } else {
        return ''
      }
    },
    SaveProforma () {
      this.$vs.loading({
        type:'sound'
      })

      const payload = {

        IdOfficine: this.IdOfficine,
        IdPriseEnCharge: this.IdPriseEnCharge,
        IdClient: this.IdClient,
        Idprescripteur: this.Idprescripteur,
        IdUser: this.IdUser,
        IdPartenaire: this.IdPartenaire,
        DatePrescrit: this.DatePrescrit,
        DateCommd: this.DateCommd,
        SphVD: this.SphVD,
        SphVG: this.SphVG,
        CylVD: this.CylVD,
        CylVG: this.CylVG,
        AxeVD: this.AxeVD,
        AxeVG: this.AxeVG,
        AddVD: this.AddVD,
        AddVG: this.AddVG,

        EdVD: this.EdVD,
        EdVG: this.EdVG,
        HdVD: this.HdVD,
        HdVG: this.HdVG,

        EcartVlOD: this.EcartVlOD,
        EcartVpOD: this.EcartVpOD,
        EcartVlOG: this.EcartVlOG,
        EcartVpOG: this.EcartVpOG,

        /* Ht_Mont1: this.Ht_Mont1,
        Ht_Mont2: this.Ht_Mont2,
        Ht_Mont3: this.Ht_Mont3, */

        Ht_Mont1_OD: this.Ht_Mont1_OD,
        Ht_Mont1_OG: this.Ht_Mont1_OG,
        Ht_Mont2_OD: this.Ht_Mont2_OD,
        Ht_Mont2_OG: this.Ht_Mont2_OG,
        Ht_Mont3_OD: this.Ht_Mont3_OD,
        Ht_Mont3_OG: this.Ht_Mont3_OG,

        /* Dctr_Mont1: this.Dctr_Mont1,
        Dctr_Mont2: this.Dctr_Mont2,
        Dctr_Mont3: this.Dctr_Mont3, */

        IdMont1: this.IdMont1,
        libelleMontureClient1: this.libelleMontureClient1,
        OrigineMont1: this.OrigineMont1,
        PrixMont1: this.PrixMont1,
        IdFourVerG1: this.IdFourVerrOG1,
        RefVerG1: this.RefVerG1,
        PrixVerG1: this.PrixVerG1,
        IdFourVerD1: this.IdFourVerrOD1,
        RefVerD1: this.RefVerD1,
        PrixVerD1: this.PrixVerD1,
        SuplementOD1: this.SuplementOD1,
        SuplementOG1: this.SuplementOG1,

        IdMont2: this.IdMont2,
        libelleMontureClient2: this.libelleMontureClient2,
        OrigineMont2: this.OrigineMont2,
        PrixMont2: this.PrixMont2,
        IdFourVerG2: this.IdFourVerrOG2,
        RefVerG2: this.RefVerG2,
        PrixVerG2: this.PrixVerG2,
        IdFourVerD2: this.IdFourVerrOD2,
        RefVerD2: this.RefVerD2,
        PrixVerD2: this.PrixVerD2,
        SuplementOD2: this.SuplementOD2,
        SuplementOG2: this.SuplementOG2,

        IdMont3: this.IdMont3,
        libelleMontureClient3: this.libelleMontureClient3,
        OrigineMont3: this.OrigineMont3,
        PrixMont3: this.PrixMont3,
        IdFourVerG3: this.IdFourVerrOG3,
        RefVerG3: this.RefVerG3,
        PrixVerG3: this.PrixVerG3,
        IdFourVerD3: this.IdFourVerrOD3,
        RefVerD3: this.RefVerD3,
        PrixVerD3: this.PrixVerD3,
        SuplementOD3: this.SuplementOD3,
        SuplementOG3: this.SuplementOG3,

        Remarque: this.Remarque,
        TotalFactBrut: this.TotalFactBrut,
        RemiseCom: this.RemiseCom,
        TotalFactNet: this.TotalFactNet,
        PartClient: this.PartClient,
        PartAssu: this.PartAssu,
        PECharge: this.PECharge,
        CmdeTSalleMontage: this.CmdeTSalleMontage,
        CmdeAuFourVerre: this.CmdeAuFourVerre,
        CmdeMontageFin: this.CmdeMontageFin,
        ComdeLivreClient: this.ComdeLivreClient,
        TypeMontureOptiq: this.TypeMontureOptiq,

        Id_OptionVerreOD1: this.Id_OptionVerreOD1,
        Prix_OptionVerreOD1: this.Prix_OptionVerreOD1,
        Id_FabricationOD1: this.Id_FabricationOD1,
        Prix_FabricationOD1: this.Prix_FabricationOD1,
        Id_ColorationOD1: this.Id_ColorationOD1,
        Prix_ColorationOD1: this.Prix_ColorationOD1,
        Id_TraitementOD1: this.Id_TraitementOD1,
        Prix_TraitementOD1: this.Prix_TraitementOD1,
        Id_OptionVerreOG1: this.Id_OptionVerreOG1,
        Prix_OptionVerreOG1: this.Prix_OptionVerreOG1,
        Id_FabricationOG1: this.Id_FabricationOG1,
        Prix_FabricationOG1: this.Prix_FabricationOG1,
        Id_ColorationOG1: this.Id_ColorationOG1,
        Prix_ColorationOG1: this.Prix_ColorationOG1,
        Id_TraitementOG1: this.Id_TraitementOG1,
        Prix_TraitementOG1: this.Prix_TraitementOG1,
        Id_OptionVerreOD2: this.Id_OptionVerreOD2,
        Prix_OptionVerreOD2: this.Prix_OptionVerreOD2,
        Id_FabricationOD2: this.Id_FabricationOD2,
        Prix_FabricationOD2: this.Prix_FabricationOD2,
        Id_ColorationOD2: this.Id_ColorationOD2,
        Prix_ColorationOD2: this.Prix_ColorationOD2,
        Id_TraitementOD2: this.Id_TraitementOD2,
        Prix_TraitementOD2: this.Prix_TraitementOD2,
        Id_OptionVerreOG2: this.Id_OptionVerreOG2,
        Prix_OptionVerreOG2: this.Prix_OptionVerreOG2,
        Id_FabricationOG2: this.Id_FabricationOG2,
        Prix_FabricationOG2: this.Prix_FabricationOG2,
        Id_ColorationOG2: this.Id_ColorationOG2,
        Prix_ColorationOG2: this.Prix_ColorationOG2,
        Id_TraitementOG2: this.Id_TraitementOG2,
        Prix_TraitementOG2: this.Prix_TraitementOG2,
        Id_OptionVerreOD3: this.Id_OptionVerreOD3,
        Prix_OptionVerreOD3: this.Prix_OptionVerreOD3,
        Id_FabricationOD3: this.Id_FabricationOD3,
        Prix_FabricationOD3: this.Prix_FabricationOD3,
        Id_ColorationOD3: this.Id_ColorationOD3,
        Prix_ColorationOD3: this.Prix_ColorationOD3,
        Id_TraitementOD3: this.Id_TraitementOD3,
        Prix_TraitementOD3: this.Prix_TraitementOD3,
        Id_OptionVerreOG3: this.Id_OptionVerreOG3,
        Prix_OptionVerreOG3: this.Prix_OptionVerreOG3,
        Id_FabricationOG3: this.Id_FabricationOG3,
        Prix_FabricationOG3: this.Prix_FabricationOG3,
        Id_ColorationOG3: this.Id_ColorationOG3,
        Prix_ColorationOG3: this.Prix_ColorationOG3,
        Id_TraitementOG3: this.Id_TraitementOG3,
        Prix_TraitementOG3: this.Prix_TraitementOG3

      }

      this.$store.dispatch('proforma/addProforma', payload)
        .then(() => {

          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })

          this.$router.push(`/apps/client/client-view/${this.IdClient}`).catch((err) => { console.log(err) })
          this.$vs.loading.close()

        })
        .catch((err) => {
          console.log(err)
          this.$vs.loading.close()
        })

    },
    moneyFormatter (montant) {
      /* Formatage du calcul */
      const val = (montant / 1).toFixed(0).replace('.', ',')

      return val.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
    },
    getMarqueName (id) {
      const singleMarque = this.$store.getters['marque/getMarqueById'](id)
      return singleMarque ? singleMarque.Libelle_marq : ''
    },

    OrdonnanceValue () {

      const ordonnance = this.client.ordonnances.find((o) => o.RefOrd === this.RefOrd)
      this.SphVD = ordonnance.OdVlSph
      this.CylVD = ordonnance.OdVlCyl
      this.AxeVD = ordonnance.OdVlAxe
      this.AddVD = ordonnance.OdAddition
      this.SphVG = ordonnance.OgVlSph
      this.CylVG = ordonnance.OgVlCyl
      this.AxeVG = ordonnance.OgVlAxe
      this.AddVG = ordonnance.OgAddition
    },
    OrigineMontureFunction (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelect = true
        this.showMontureClientInput = false

      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInput = true
        this.showMontureStockSelect = false
      }

    },
    OrigineMontureProposition2 (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelectP2 = true
        this.showMontureClientInputP2 = false
      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInputP2 = true
        this.showMontureStockSelectP2 = false
      }
    },
    OrigineMontureProposition3 (origine) {
      if (origine === 'stock') {
        this.showMontureStockSelectP3 = true
        this.showMontureClientInputP3 = false
      }
      if (origine === 'monture_client' || origine === 'autre') {
        this.showMontureClientInputP3 = true
        this.showMontureStockSelectP3 = false
      }
    },
    goToNewPrescripteur () {
      if (this.Idprescripteur === 'new_prescripteur') {
        this.popoupActivePescripteur = true
        this.Idprescripteur = null
      }
    },
    SavePrescripteur () {
      const payload = {
        IdCenterHospi:this.IdCenterHospi,
        NomPresc: this.NomPresc,
        PrenomPresc: this.PrenomPresc,
        taux: this.taux,
        Ristourne: this.Ristourne,
        TelPresc: this.TelPresc,
        Mobile: this.Mobile,
        FaxPresc: this.FaxPresc,
        AdrPresc: this.AdrPresc,
        EmailPresc: this.EmailPresc,
        Remarque: this.RemarqueP
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('prescripteur/addPrescripteur', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'Centre Ajouté !',
            text: 'success !!',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'success'
          })
          this.Idprescripteur = resp.data._id
          this.popoupActivePescripteur = false
          this.reset_data_prescripteur()

          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_prescripteur () {
      this.IdCenterHospi = null
      this.NomPresc = ''
      this.PrenomPresc = ''
      this.RemarqueP = false
      this.TelPresc = ''
      this.Mobile = ''
      this.FaxPresc = ''
      this.AdrPresc = ''
      this.EmailPresc = ''
    },
    SaveCentreHospitalier () {
      const payload = {
        RaisonSoc: this.RaisonSoc,
        AdrCli: this.AdrCli,
        MailCli: this.MailCli,
        TelCli1: this.TelCli1,
        TelCli2: this.TelCli2,
        TelCli3: this.TelCli3,
        MobCli1: this.MobCli1,
        MobCli2: this.MobCli2,
        FaxCli: this.FaxCli
      }

      this.$vs.loading({
        type:'sound'
      })

      this.$store.dispatch('centre_hospitalier/addCentreHospitalier', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdCenterHospi = resp.data._id
          this.reset_data_centre()
          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_centre () {
      this.RaisonSoc = ''
      this.AdrCli = ''
      this.MailCli = ''
      this.TelCli1 = ''
      this.TelCli2 = ''
      this.TelCli3 = ''
      this.MobCli1 = ''
      this.MobCli2 = ''
      this.FaxCli = ''
    },
    getClientById () {
      this.$vs.loading({
        type: 'sound'
      })
      this.$store.dispatch('client/getClientById', this.$route.params.clientId)
        .then((resp) => {
          this.client = resp.data
          this.IdClient = this.client._id
          this.ordonnances = resp.data.ordonnances
          this.$vs.loading.close()

        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    createClient () {

      const payload = {
        IdOfficine: this.IdOfficine,
        IdUser: this.IdUser,
        Civilite: this.Civilite,
        Nom: this.Nom,
        Prenoms: this.Prenoms,
        Alias: this.Alias,
        Profession: this.Profession,
        DateNaiss: this.DateNaiss,
        Activitextra: this.Activitextra,
        Langue: this.Langue,
        adresse: this.adresse,
        email: this.email,
        pays: this.pays,
        ville: this.ville,
        quartier: this.quartier,
        localite: this.localite,
        tel: this.tel,
        mobile: this.mobile,
        fax: this.fax,
        cp: this.cp,
        remarque: this.remarque,
        CarteFidelite: this.CarteFidelite,
        Assure: this.Assure,
        DCD: this.DCD,
        ChefFamille: this.ChefFamille,
        PasSMS: this.PasSMS,
        PasMail: this.PasMail

      }
      this.$vs.loading({
        type: 'sound'
      })

      this.$store.dispatch('client/addClient', payload)
        .then((resp) => {
          this.$vs.notify({
            title: 'success',
            text: 'success',
            iconPack: 'feather',
            icon: 'icon-check',
            color: 'primary'
          })
          this.IdClient = resp.data._id
          this.popupActiveClient = false

          this.Civilite = null
          this.Nom = null
          this.Prenoms = null
          this.Alias = null
          this.Profession = null
          this.DateNaiss = null
          this.Activitextra = null
          this.Langue = null
          this.adresse = null
          this.remarque = null
          this.CarteFidelite = false
          this.Assure = false
          this.DCD = false
          this.ChefFamille = false
          this.PasSMS = false
          this.PasMail = false

          this.$vs.loading.close()
        })
        .catch(err => {
          console.error(err)
          this.$vs.loading.close()
        })
    },
    reset_data_client () {
      this.Civilite = null
      this.Nom = null
      this.Prenoms = null
      this.Alias = null
      this.Profession = null
      this.DateNaiss = null
      this.Activitextra = null
      this.Langue = null
      this.adresse = null
      this.remarque = null
      this.CarteFidelite = false
      this.Assure = false
      this.DCD = false
      this.ChefFamille = false
      this.PasSMS = false
      this.PasMail = false

      this.IdClient = null
      this.popupActiveClient = false

    },
    getClientsOfficine (id) {
      this.$store.dispatch('client/getClientsOfficine', id)
        .catch((error) => { console.log(error) })
    },
    getPrescripteurs () {
      this.$store.dispatch('prescripteur/getPrescripteurs')
        .catch(err => { console.log(err) })
    },
    getCentreHospitalier () {
      this.$store.dispatch('centre_hospitalier/getCentreHospitalier')
        .catch((err) => { console.log(err) })
    },
    getCategorieArticles () {
      this.$store.dispatch('categorie_article/getCategorieArticles')
        .catch(err => {
          console.error(err)
        })
    },
    getMarques () {
      this.$store.dispatch('marque/getMarques')
        .catch(err => {
          console.error(err)
        })
    },
    activeUserInfo () {
      this.activeUserInfos = JSON.parse(localStorage.getItem('userInfo'))
      this.IdOfficine = this.activeUserInfos.IdOfficine
    },
    getPays () {
      this.$store.dispatch('pays/getPays')
        .catch((err) => { console.log(err) })
    },
    getVilles () {
      this.$store.dispatch('pays/getVilles')
        .catch(err => {
          console.error(err)
        })
    },
    getQuartiers () {
      this.$store.dispatch('pays/getQuartiers')
        .catch(err => {
          console.error(err)
        })
    },
    getTypeDeVerres () {
      this.$store.dispatch('verre/getTypeDeVerres')
        .catch((error) => { console.log(error) })
    },
    getMatieresVerre () {
      this.$store.dispatch('verre/getMatieresVerre')
        .catch((error) => { console.log(error) })
    },
    getIndiceVerre () {
      this.$store.dispatch('verre/getIndiceVerre')
        .catch((error) => { console.log(error) })
    },
    getFournisseursVerres () {
      this.$store.dispatch('verre/getFournisseursVerres')
        .catch((error) => { console.log(error) })
    },
    getLogo () {
      if (this.$store.state.entete_piedpage.logo) {
        this.$store.dispatch('entete_piedpage/getLogo')
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getPiedPage () {
      if (this.$store.state.entete_piedpage.piedpage) {
        this.$store.dispatch('entete_piedpage/getPiedPage')
          .catch((err) => {
            console.error(err)
          })
      }
    },
    getTraitement () {
      this.$store.dispatch('verre/getTraitement')
        .catch((error) => { console.log(error) })
    },
    getGammeVerres () {
      this.$store.dispatch('verre/getGammeVerres')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getVersionVerre () {
      this.$store.dispatch('verre/getVersionVerre')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getFabrications () {
      this.$store.dispatch('verre/getFabrications')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    },
    getColorations () {
      this.$store.dispatch('verre/getColorations')
        .then(() => {
        })
        .catch((error) => {
          console.log(error)
        })
    }
  },
  created () {
    this.getLogo()
    this.getPiedPage()
    this.activeUserInfo()
    this.getClientById()
    if (!(this.$store.state.prescripteur.prescripteurs.length > 0)) this.getPrescripteurs()
    if (!(this.$store.state.centre_hospitalier.centre_hospitaliers.length > 0)) this.getCentreHospitalier()
    if (!(this.$store.state.categorie_article.categorie_articles.length > 0)) this.getCategorieArticles()
    if (!(this.$store.state.marque.marques.length > 0)) this.getMarques()
    if (!(this.$store.state.verre.fournisseurs_verres.length > 0)) this.getFournisseursVerres()
    if (!(this.$store.state.verre.types_verre.length > 0)) this.getTypeDeVerres()
    if (!(this.$store.state.verre.matieres_verres.length > 0))  this.getMatieresVerre()
    if (!(this.$store.state.verre.indices.length > 0)) this.getIndiceVerre()
    if (!(this.$store.state.verre.traitements.length > 0)) this.getTraitement()
    if (!(this.$store.state.verre.gammes_verres.length > 0)) this.getGammeVerres()
    if (!(this.$store.state.verre.versions.length > 0)) this.getVersionVerre()
    if (!(this.$store.state.verre.fabrications.length > 0)) this.getFabrications()
    if (!(this.$store.state.verre.colorations.length > 0)) this.getColorations()
  }
}
</script>

<style scoped>
.not-data-table{
  display: none;
}

/* table {
  border:   1px solid black;
} */
.trCustom, .thCustom {
  border: 1px solid black;
  padding: 5px;
}
</style>
